import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
    faqData: {
        // payment
        payment: {
            icon: 'CreditCardIcon',
            title: 'Оплата',
            subtitle: 'Яка ліцензія мені потрібна?',
            qandA: [{
                    question: 'Моя підписка автоматично поновлюється?',
                    ans: 'Кондитерські вироби з пудингом, печивом, конфетами, зизифусом, зизифусом, порошковою начинкою. Желейні зерна Gummi Bears Солодкий рулет bonbon muffin лакриця. Вафельні льодяники з кунжутом. Брауні, макаруни, печиво, кекс, цукерки, карамелі, тірамісу. Вівсяний пиріг Шоколадний пиріг Солодке желе Брауні Бісквіт Марципан. Зизифус пончик марципан шоколадний батончик. Зизифус цукор сливовий драже тірамісу глазур чізкейк.',
                },
                {
                    question: 'Чи можу я зберегти елемент в інтрамережі, щоб усі мали доступ?',
                    ans: 'Солодкий пиріг цукерки желейні. Кунжутне печиво цукрова слива. Солодкий рулет із фруктовим тортом. Карамелі, лакричне печиво, морозиво, фруктовий пиріг, торт із солодкої вати. Пряниковий желейний пряниковий пудинг з пончиками. Пиріг із цукерками з цукерками з тіста Gummi Bears. Пиріг яблучний пиріг морквяний пиріг.',
                },
                {
                    question: 'Що означає невиключний?',
                    ans: 'Терпкі цукерки, драже, льодяник, фруктовий пиріг, вівсяний пиріг. Печиво желе желе мигдальне печиво глазур желе боби суфле торт солодкий. Мигдальне печиво з кунжутом, чізкейк, пиріг, цукрова слива. Десерт желе солодкий кекс шоколадні цукерки пиріг тутсі рулет марципан.',
                },
                {
                    question: 'Чи звичайна ліцензія те саме, що редакційна ліцензія?',
                    ans: 'Чізкейк, мафін, капкейк, драже, лимонні краплі, тірамісу, цукерки, шоколадний торт. Терпкий круасан з зефіру. Терпкий десерт тірамісу марципан льодяник лимон краплі. Торт, цукерки, бонбони, ведмедики гуммі, начинка з желейними бобами, брауні, зизифус, кекс. Пончик круасан желе торт марципан. Солодка марципанове печиво вафельний рулет. Солодкий кекс з рулетом Тутсі.',
                },
            ],
        },
        // delivery
        delivery: {
            icon: 'ShoppingBagIcon',
            title: 'Доставка',
            subtitle: 'Яка ліцензія мені потрібна?',
            qandA: [{
                    question: 'Моя підписка автоматично поновлюється?',
                    ans: 'Кондитерські вироби з пудингом, печивом, конфетами, зизифусом, зизифусом, порошковою начинкою. Желейні зерна Gummi Bears Солодкий рулет bonbon muffin лакриця. Вафельні льодяники з кунжутом. Брауні, макаруни, печиво, кекс, цукерки, карамелі, тірамісу. Вівсяний пиріг Шоколадний пиріг Солодке желе Брауні Бісквіт Марципан. Зизифус пончик марципан шоколадний батончик. Зизифус цукор сливовий драже тірамісу глазур чізкейк.',
                },
                {
                    question: 'Чи можу я зберегти елемент в інтрамережі, щоб усі мали доступ?',
                    ans: 'Солодкий пиріг цукерки желейні. Кунжутне печиво цукрова слива. Солодкий рулет із фруктовим тортом. Карамелі, лакричне печиво, морозиво, фруктовий пиріг, торт із солодкої вати. Пряниковий желейний пряниковий пудинг з пончиками. Пиріг із цукерками з цукерками з тіста Gummi Bears. Пиріг яблучний пиріг морквяний пиріг.',
                },
                {
                    question: 'Що означає невиключний?',
                    ans: 'Терпкі цукерки, драже, льодяник, фруктовий пиріг, вівсяний пиріг. Печиво желе желе мигдальне печиво глазур желе боби суфле торт солодкий. Мигдальне печиво з кунжутом, чізкейк, пиріг, цукрова слива. Десерт желе солодкий кекс шоколадні цукерки пиріг тутсі рулет марципан.',
                },
                {
                    question: 'Чи звичайна ліцензія те саме, що редакційна ліцензія?',
                    ans: 'Чізкейк, мафін, капкейк, драже, лимонні краплі, тірамісу, цукерки, шоколадний торт. Терпкий круасан з зефіру. Терпкий десерт тірамісу марципан льодяник лимон краплі. Торт, цукерки, бонбони, ведмедики гуммі, начинка з желейними бобами, брауні, зизифус, кекс. Пончик круасан желе торт марципан. Солодка марципанове печиво вафельний рулет. Солодкий кекс з рулетом Тутсі.',
                },
            ],
        },
        // cancellation and return
        cancellationReturn: {
            icon: 'RefreshCwIcon',
            title: 'Повернення',
            subtitle: 'Яка ліцензія мені потрібна?',
            qandA: [{
                    question: 'Can my security guard or neighbour receive my shipment if I am not available?',
                    ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
                },
                {
                    question: 'How can I get the contact number of my delivery agent?',
                    ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
                },
                {
                    question: 'How can I cancel my shipment?',
                    ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
                },
                {
                    question: 'I have received a defective/damaged product. What do I do?',
                    ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
                },
                {
                    question: 'How do I change my delivery address?',
                    ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                },
                {
                    question: 'What documents do I need to carry for self-collection of my shipment?',
                    ans: 'At tempor commodo ullamcorper a lacus vestibulum. Ultrices neque ornare aenean euismod. Dui vivamus arcu felis bibendum. Turpis in eu mi bibendum neque egestas congue. Nullam ac tortor vitae purus faucibus ornare suspendisse sed. Commodo viverra maecenas accumsan lacus vel facilisis volutpat est velit. Tortor consequat id porta nibh. Id aliquet lectus proin nibh nisl condimentum id venenatis a. Faucibus nisl tincidunt eget nullam non nisi. Enim nunc faucibus a pellentesque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus. Nec nam aliquam sem et tortor consequat id. Fringilla est ullamcorper eget nulla facilisi. Morbi tristique senectus et netus et.',
                },
                {
                    question: 'What are the timings for self-collecting shipments from the Delhivery Branch?',
                    ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Euismod lacinia at quis risus sed vulputate odio ut enim. Dictum at tempor commodo ullamcorper a lacus vestibulum.',
                },
            ],
        },
        // my orders
        myOrders: {
            icon: 'PackageIcon',
            title: 'Мої замовлення',
            subtitle: 'Яка ліцензія мені потрібна?',
            qandA: [{
                    question: 'Can I avail of an open delivery?',
                    ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
                },
                {
                    question: 'I haven’t received the refund of my returned shipment. What do I do?',
                    ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
                },
                {
                    question: 'How can I ship my order to an international location?',
                    ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
                },
                {
                    question: 'I missed the delivery of my order today. What should I do?',
                    ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
                },
                {
                    question: 'The delivery of my order is delayed. What should I do?',
                    ans: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
                },
            ],
        },
        // product and services
        productServices: {
            icon: 'SettingsIcon',
            title: 'Продукт і послуги',
            subtitle: 'Яка ліцензія мені потрібна?',
            qandA: [{
                    question: 'How can I register a complaint against the courier executive who came to deliver my order?',
                    ans: 'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
                },
                {
                    question: 'The status for my shipment shows as ‘not picked up’. What do I do?',
                    ans: 'Sweet pie candy jelly. Sesame snaps biscuit sugar plum. Sweet roll topping fruitcake. Caramels liquorice biscuit ice cream fruitcake cotton candy tart. Donut caramels gingerbread jelly-o gingerbread pudding. Gummi bears pastry marshmallow candy canes pie. Pie apple pie carrot cake.',
                },
                {
                    question: 'How can I get a proof of delivery for my shipment?',
                    ans: 'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan.',
                },
                {
                    question: 'How can I avail your services?',
                    ans: 'Cheesecake muffin cupcake dragée lemon drops tiramisu cake gummies chocolate cake. Marshmallow tart croissant. Tart dessert tiramisu marzipan lollipop lemon drops. Cake bonbon bonbon gummi bears topping jelly beans brownie jujubes muffin. Donut croissant jelly-o cake marzipan. Liquorice marzipan cookie wafer tootsie roll. Tootsie roll sweet cupcake.',
                },
            ],
        },
    },
}

mock.onGet('/faq/data').reply(config => {
    const { q = '' } = config.params
    const queryLowered = q.toLowerCase()

    const filteredData = {}

    Object.entries(data.faqData).forEach(entry => {
        const [categoryName, categoryObj] = entry
        // eslint-disable-next-line arrow-body-style
        const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
            return qAndAObj.question.toLowerCase().includes(queryLowered)
        })
        if (filteredQAndAOfCategory.length) {
            filteredData[categoryName] = {...categoryObj, qandA: filteredQAndAOfCategory }
        }
    })

    return [200, filteredData]
})